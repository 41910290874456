import React, { Component } from 'react';
import searchIcon from "../assets/search.svg";

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.SearchBox(this.searchInput, {});
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
    this.searchBox.bindTo('bounds', map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = ({ map, setSearchCenter } = this.props) => {
    const selected = this.searchBox.getPlaces();
    const { 0: place } = selected;
    if (!place.geometry) return;
    setSearchCenter([place.geometry.location.lat(), place.geometry.location.lng()]);
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = '';
    this.props.setSearchCenter()
  }

  render() {
    return (
        <div className="col-12 col-md-8 mt-3 mt-md-0">
          <div className="search">
            <img src={searchIcon} alt="Buscar" />
            <input
                ref={(ref) => {
                  this.searchInput = ref;
                }}
                type="text"
                onFocus={this.clearSearchBox}
                className="switch-button selected"
                placeholder="Ingresá tu dirección y encontrá los restaurantes más cercanos"
            />
            <div>Buscar</div>
          </div>
        </div>
    );
  }
}

export default SearchBox;
