import React from 'react'

export default function Title({title, subtitle}) {
  return(
        <div className="container">
          <div className="results">
            <h1 className="results-title">{title}</h1>
            <div className="results-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        </div>
  )
}
