import React from 'react'

export default function HeroWithTitle({title, image, subtitle, width}) {
  return(
      <div>
        <div style={{ position: 'relative' }}>
          <div className="hide-on-mobile">
            <div className="scroll-container">
          <span className="scroll-icon">
            <span className="scroll-icon__dot"></span>
          </span>
            </div>
            <span className="view-results">Ver todas las opciones</span>
          </div>
          <img className="main-hero" src={image} width={width} alt={title} />
        </div>
        <div className="container">
          <div className="results">
            <h1 className="results-title">{title}</h1>
            <div className="results-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
          </div>
        </div>
      </div>
  )
}
