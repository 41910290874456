import React from 'react'

const Burger = React.forwardRef((props, ref) => {
  const {restaurantName, image, logo, burgerName, link, neighbourhood, className, containerClassName, showLogo, selected} = props;
  const burgers = burgerName.split(',');
  return(
      <div ref={ref} className={`${containerClassName ? containerClassName : "col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4"}`}>
        <a href={link} className={className}>
          <div className={`restaurant-card ${selected ? 'selected' : ''}`}>
            {
              showLogo && <img src={logo} className="restaurant-logo" alt={restaurantName} />
            }
            <div className={`info`} style={!showLogo ? {marginLeft: 4} : {}}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <span className="title">{restaurantName}</span>
                  <div style={{ marginBottom: 8 }}>
                    {
                      burgers.length <= 3 ? burgers.map(b => <small style={{ display: 'block' }}>{b}</small>) : `${burgers.length} opciones NotCo`
                    }
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="marker" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <small className="neighborhood">{neighbourhood}</small>
                  </div>
                </div>
                <button className="buy-button">PEDÍ LA TUYA</button>
              </div>
            </div>
            <img src={image} className="burger-image" alt={restaurantName} />
          </div>
        </a>
      </div>
  )
})

export default Burger;
