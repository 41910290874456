import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import {GRAPHCMS_ENDPOINT, GRAPHCMS_TOKEN} from "./constants";
import fetch from 'isomorphic-fetch'

const httpLink = createHttpLink({
  uri: GRAPHCMS_ENDPOINT,
  headers: {
    Authorization: `Bearer ${GRAPHCMS_TOKEN}`,
  },
  fetch
});


export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

export const buildNeighborhoods = (locations) => {
  const arr = []
  locations.forEach(l => {
    if(!arr.includes(l.neighborhood)) {
      arr.push(l.neighborhood)
    }
  })

  return arr.join(', ');
}

export function distance(lat1, lon1, lat2, lon2) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344
    return dist;
  }
}
