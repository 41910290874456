import React from 'react'
import Burger from "./Burger";
import {buildNeighborhoods} from "../utils";

export default function ListView({loading, restaurants}) {
  if(loading) {
    return <span>Loading</span>
  }
  return(
      <div className="container">
        <div className="row mt-4">
          {
            restaurants?.map(r => <Burger restaurantName={r.restaurantName} image={r.image.url} logo={r.restaurantLogo.url} burgerName={r.burgerName} link={r.link} neighbourhood={buildNeighborhoods(r.restaurantLocations)} className={r.className} showLogo />)
          }
        </div>
      </div>
  )
}
