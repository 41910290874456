import {gql} from "@apollo/client";

export const GET_RESTAURANTS = gql`
    query {
        restaurants(orderBy: order_ASC)  {
            id
            restaurantName
            restaurantLogo {
                url: url(
                    transformation: {
                        document: { output: { format: webp } }
                        image: { resize: { width: 200, height: 200, fit: clip } }
                    }
                )
            }
            image {
                url: url(
                    transformation: {
                        document: { output: { format: webp } }
                        image: { resize: { width: 200, height: 200, fit: clip } }
                    }
                )
            }
            burgerName
            link
            neighbourhood
            className
            restaurantLocations {
                geolocation {
                    latitude
                    longitude
                }
                address
                neighborhood
            }
        }
    }
`;

export const GET_MAIN_IMAGE = gql`
    query {
        mainInfos {
            mainImage {
                url: url(transformation: { document: { output: { format: webp } } })
            }
            title
            body {
                html
            }
        }
    }`;
