import React, {useLayoutEffect, useState, useEffect, createRef} from 'react';
import { useQuery } from '@apollo/client';
import ReactGA from 'react-ga';
import {GET_MAIN_IMAGE, GET_RESTAURANTS} from "./queries";
import MapView from "./components/MapView";
import ListView from "./components/ListView";
import ContactBanner from "./components/ContactBanner";
import Footer from "./Footer";
import AutoComplete from "./components/Autocomplete";
import './App.css';
import MoonLoader from "react-spinners/MoonLoader";
import {distance} from './utils'
import { useExperiment, emitter, experimentDebugger } from '@marvelapp/react-ab-test';

import logo from './assets/notco-logo.png';
import Toggle from "./components/Toggle";
import HeroWithTitle from "./components/HeroWithTitle";
import Title from "./components/Title";


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
experimentDebugger.enable();
emitter.defineVariants("NotBurger", ["Banner", "Texto"]);
ReactGA.initialize('UA-67092914-6');

emitter.addPlayListener(function(experimentName, variantName) {
  console.log(`Displaying experiment ${experimentName} variant ${variantName}`);
  // window.ga('config', 'UA-67092914-6', {
  //   'custom_map': {'experiment': 'experiment_name'}
  // });
  // window.ga('event', 'experiment_dimension', {'experiment_name': variantName});
  ReactGA.ga((tracker) => {

    ReactGA.set({ dimension1: variantName });
    ReactGA.set({ experiment: variantName });


    // ReactGA.pageview(window.location.pathname, { dimension1: variantName });
  });
});

function App() {

  const [showMap, setShowMap] = useState(true);
  const { loading: loadingRestaurants, data: {restaurants} = [] } = useQuery(GET_RESTAURANTS);
  const { loading: loadingMainInfo, data: {mainInfos} = [] } = useQuery(GET_MAIN_IMAGE);
  const BUENOS_AIRES = [-34.603722, -58.381592]
  const [location, setLocation] = useState(BUENOS_AIRES)
  const [mapApi, setMapApi] = useState()
  const [refRestaurants, setRefRestaurants] = useState([])
  const [mapInstance, setMapInstance] = useState()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [searchCenter, setSearchCenter] = useState()
  const [filteredRestaurants, setFilteredRestaurants] = useState([])

  const { selectVariant } = useExperiment("NotBurger");

  useEffect(() => {
    if(!loadingRestaurants) {
      const rests = restaurants.map(r => {
        return {data: r, ref: createRef()}
      })
      setRefRestaurants(rests)
    }
  }, [loadingRestaurants, restaurants])

  useEffect(() => {
    if(!loadingRestaurants && searchCenter) {
      const filtered = restaurants.filter(r => r.restaurantLocations.some(l => distance(l.geolocation.latitude, l.geolocation.longitude, searchCenter[0], searchCenter[1]) < 3))
      setFilteredRestaurants(filtered)
    } else {
      setFilteredRestaurants(restaurants)
    }
  }, [searchCenter, loadingRestaurants, restaurants])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLocation([position.coords.latitude, position.coords.longitude])
      });
    }
  }, [])

  const [width] = useWindowSize();

  const isMobile = width < 768;

  if(loadingRestaurants || loadingMainInfo) {
    return(
        <>
          <nav className="navbar navbar-light container">
            <a className="navbar-brand" href="https://notco.com/ar/">
              <img src={logo} width="50" height="50" alt="logo"/>
            </a>
          </nav>
          <div className="loader">
            <MoonLoader color={'#ffcd00'} loading size={50} />
          </div>
        </>
    )
  }

  const variant = selectVariant({
    Banner: <HeroWithTitle image={mainInfos[0].mainImage.url} width={width} title={mainInfos[0].title} subtitle={mainInfos[0].body.html}/>,
    Texto: <Title title={mainInfos[0].title} subtitle={mainInfos[0].body.html}/>
  });

  return (
    <div>
      <nav className="navbar navbar-light container">
        <a className="navbar-brand" href="https://notco.com/ar/">
          <img src={logo} width="50" height="50" alt="logo"/>
        </a>
      </nav>
      {variant}
      {
        mapLoaded &&
        <div className="container">
          <div className="row">
            <Toggle showMap={showMap} setShowMap={setShowMap}/>
            <AutoComplete mapApi={mapApi} map={mapInstance} setSearchCenter={setSearchCenter}/>
          </div>
        </div>
      }
      {
        showMap ?
            <MapView
                loading={loadingRestaurants}
                restaurants={refRestaurants}
                center={location}
                defaultCenter={BUENOS_AIRES}
                isMobile={isMobile}
                setMapApi={setMapApi}
                setMapInstance={setMapInstance}
                setMapLoaded={setMapLoaded}
            />
            :
            <div>
              <ListView loading={loadingRestaurants} restaurants={filteredRestaurants} />
              {
                filteredRestaurants?.length === 0 &&
                <div className="container">
                  <div className="results">
                    <div className="results-subtitle">No encontramos restaurantes en esta zona</div>
                  </div>
                </div>
              }
            </div>
      }
      <ContactBanner/>
      <Footer/>
    </div>
  );
}

export default App;
