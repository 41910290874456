import React, {useState} from 'react'
import GoogleMapReact from "google-map-react";
import Burger from "./Burger";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {buildNeighborhoods, distance} from '../utils'

function renderList(loading, restaurants, isMobile, selected, center) {
  if(loading) {
    return <span>Loading</span>
  }

  const filtered = restaurants.filter(r => r.data.restaurantLocations.some(l => distance(l.geolocation.latitude, l.geolocation.longitude, center[0], center[1]) < 10));

  if(filtered.length === 0) {
    return(
        <div className={`${isMobile ? 'container' : ''} col-12 col-md-4 mt-4 mt-md-0 text-center`} style={!isMobile ? { height: '100vh', overflow: 'scroll'} : {}}>
          <span className="text-center">No hay restaurantes disponibles en esta zona.</span>
        </div>
    );
  }

  return(
      <div className={`${isMobile ? 'container' : ''} col-12 col-md-4 mt-4 mt-md-0`} style={!isMobile ? { height: '100vh', overflow: 'scroll'} : {}}>
        {
          filtered.map(r => {
            return <Burger
                ref={r.ref}
                key={r.data.id}
                selected={selected === r.data.id}
                containerClassName="mb-4"
                restaurantName={r.data.restaurantName}
                image={r.data.image.url}
                logo={r.data.restaurantLogo.url}
                burgerName={r.data.burgerName}
                link={r.data.link}
                neighbourhood={buildNeighborhoods(r.data.restaurantLocations)}
                className={r.data.className}
                showLogo={isMobile}
            />
          })
        }
      </div>
  )
}

const Tooltip = ({name, address}) => {
  return(
      <div>
        <div className="tooltip-title">{name}</div>
        <div>{address}</div>
      </div>
  )
}

function Marker({lat, lng, image, name, address, onClick}) {
  return(
      <Tippy content={<Tooltip name={name} address={address}/>}>
        <img src={image} className="marker-logo" lat={lat}
           lng={lng} data-tip="hello world" onClick={onClick} alt={address}/>
      </Tippy>
  )
}

const renderMarkers = (restaurants, setSelected) => {
  let markers = []
  restaurants?.forEach(restaurant => {
    restaurant.data.restaurantLocations?.forEach((location) => markers.push(
        <Marker
            image={restaurant.data.restaurantLogo?.url}
            name={restaurant.data.restaurantName}
            lat={location.geolocation?.latitude}
            lng={location.geolocation?.longitude}
            address={location.address}
            key={location.address}
            onClick={() => {
              setSelected(restaurant.data.id)
              restaurant.ref.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
              })
            }}
        />
        )
    )
  })
  return markers;
}

export default function MapView({restaurants, loading, center, defaultCenter, isMobile, setMapApi, setMapInstance, setMapLoaded}) {
  const [selected, setSelected] = useState(null)
  const [currenctCenter, setCurrentCenter] = useState(center)
  return(
      <div  style={!isMobile ? { height: '100vh', width: '100%', overflow: 'scroll'} : {}} className={`mt-5 ${isMobile ? '' : 'row'}`}>
        <div className="col-12 col-md-8">
          <div style={{ height: '100vh', width: '100%'}}>
            <GoogleMapReact
                onClick={() => setSelected(null)}
                center={center}
                defaultCenter={defaultCenter}
                defaultZoom={14}
                bootstrapURLKeys={{
                  key: 'AIzaSyCncDULjNVtZa-T38QpSUoqWmB3lJ5vDZU',
                  libraries: ['places'],
                  language: 'es',
                  region: 'ar',
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ maps, map }) => {
                  setMapApi(maps)
                  setMapInstance(map)
                  setMapLoaded(true)
                }}
                onChange={({center}) => setCurrentCenter([center.lat, center.lng])}
            >
              {
                renderMarkers(restaurants, setSelected)
              }
            </GoogleMapReact>
          </div>
        </div>
        {
          renderList(loading, restaurants, isMobile, selected, currenctCenter)
        }
      </div>
  )
}
