import React from 'react'

export default function Toggle({showMap, setShowMap}) {


  return(
      <div className="col-12 col-md-4">
        <div className="switch">
          <div className={`switch-button ${showMap ? 'selected' : ''}`} onClick={() => setShowMap(!showMap)}>Mapa</div>
          <div className={`switch-button ${!showMap ? 'selected' : ''}`} onClick={() => setShowMap(!showMap)}>Lista</div>
        </div>
      </div>
  )
}
