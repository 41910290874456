import React from 'react';

export default function ContactBanner() {
  return(
      <div className="contact-container mt-4">
        <div className="container row justify-content-between align-items-center">
          <div className="col-xs-12 col-sm-12 col-md-10">
            <p className="contact-title m-md-0">Queremos que la ruta de la <span>NOT</span>BURGER sea cada día más grande<br/>¿Querés sumarte?</p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2 d-flex justify-content-md-end align-items-md-end">
            <a className="contact-button" href="mailto:contacto.ar@thenotcompany.com?subject=¡Quiero sumarme a la ruta de la NotBurger!">CONTACTANOS</a>
          </div>
        </div>
      </div>
  )
}
